import * as React from "react";
import PageLayout from "../components/PageLayout";
import { StaticImage } from "gatsby-plugin-image";

// markup
const MysticWizards = () => {
  return (
    <PageLayout>
      <div className="center">
        <div className="content">
          <h1 className="title">EGGsly.io by Karrie Ross</h1>
          <div className="image">
            <StaticImage src="../images/eggsly-spot.jpg" alt="EGGsly.io by Karrie Ross" layout="constrained" width={400} />
          </div>
          <div className="description">
            <p>EGGsly™ is a gender-neutral collection of 1,000 entirely hand drawn, randomly generated NFTs built from 276 unique traits and exists on the Ethereum Blockchain.</p>
          </div>
        </div>
      </div>
      <hashku-mint api-url="https://api.hashku.com/api" team-slug="karrie-ross" project-slug="eggsly" contract-address="0x70855E81D0E03e7BD1C3a3A32DEa6aa08967746D" contract-network="1" infura-id="3ed982906a2d473f8918f0730b2ea194" signup-only="no" />
    </PageLayout>
  );
};

export default MysticWizards;
